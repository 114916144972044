import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-placeholders',
  templateUrl: './placeholders.component.html',
  styleUrls: ['./placeholders.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceholdersComponent implements OnInit, OnDestroy {
  // ------------------------------------------------------------------------------
  // @ Attributes
  // ------------------------------------------------------------------------------

  listPlaceholders: any[];
  placeholderForm: FormGroup;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  // ------------------------------------------------------------------------------
  // @ Constructor
  // ------------------------------------------------------------------------------

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<PlaceholdersComponent>,
  ) {}

  // ------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // ------------------------------------------------------------------------------

  /**
   * On Init
   */
  ngOnInit(): void {
    // Get Document's placeholders value
    this.listPlaceholders = this.data.listPlaceholders;

    // Initialize form
    this._initForm();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // ------------------------------------------------------------------------------
  // @ Public methods
  // ------------------------------------------------------------------------------

  /** On form submit */
  onSubmit(): void {
    this.closeComponent(true, this.placeholderForm.getRawValue());
  }

  /**
   * Close modal component
   */
  closeComponent(success: boolean = false, placeholders: any = null): void {
    // Close the dialog
    this.matDialogRef.close({ success, placeholders });
  }

  // ------------------------------------------------------------------------------
  // @ Private methods
  // ------------------------------------------------------------------------------

  private _initForm(): void {
    if (this.data && this.listPlaceholders && this.listPlaceholders.length > 0) {
      this.placeholderForm = new FormGroup({});
      this.listPlaceholders.forEach((item) => {
        this.placeholderForm.addControl(item.name, new FormControl(item.defaultValue));
      });
    }
  }
}
