import { CertificateProfile } from 'app/modules/entities/entities.types';

export interface Document {
  id?: string;
  uuid?: string;
  name?: string;
  accountUuid?: string;
  ownerEmail?: string;
  creationDate?: string;
  s3OriginalPath?: string;
  s3SignedPath?: string;
  signers?: DocumentSigner[];
  state?: string;
  withTsa?: boolean;
  parallel?: boolean;
  contentType?: string;
  attachments?: Attachment[];
  accountSignature?: boolean;
  folderName?: string;
  rejectedMessage?: string;
  signatureDeadline?: number;
  notificationFrequency?: NotificationFrequency;
  blockingReason?: string;
  thumbnail?: string;
  notificationMessage?: string;
  archivedMessage?: string;
  expirationDate?: Date;
}

export interface DocumentSigner {
  documentUuid?: string;
  order?: number;
  signer?: Signer;
  signDate?: string;
  s3BeforeSignPath?: string;
  s3AfterSignPath?: string;
  messageIfError?: string;
  signature?: any;
  signatures?: any[];
  signaturesAttachments?: any;
  notifications?: string[];
  certificateProfile?: CertificateProfile;
  approver?: boolean;
  emailOTP?: boolean;
  smsOTP?: boolean;
  whatsappOTP?: boolean;
  sendSignWhatsapp?: boolean;
  external?: boolean;
  rejected?: boolean;
  docSignerId?: string;
  state?:
    | 'WAIT_FOR_SIGN'
    | 'DELAY'
    | 'NOT_EXIST'
    | 'SIGNED'
    | 'SIGNING'
    | 'ERROR'
    | 'BLOCKING';
}

export interface Signer {
  uuid?: string;
  accountUuid?: string;
  order?: number;
  id?: string;
  idType?: string;
  names?: string;
  lastNames?: string;
  email?: string;
  phone?: string;
  cipherPhone?: string;
  countryCode?: string;
  certificate?: any;
  predefinedSignature?: boolean;
  signature?: any;
  signatures?: any[];
  signaturesAttachments?: any;
  notifications?: string[];
  external?: boolean;
  updateType?: boolean;
  updatePosition?: boolean;
  approver?: boolean;
  rejected?: boolean;
  state?:
  | 'WAIT_FOR_SIGN'
  | 'DELAY'
  | 'NOT_EXIST'
  | 'SIGNED'
  | 'SIGNING'
  | 'ERROR'
  | 'BLOCKING';
  emailOTP?: boolean;
  smsOTP?: boolean;
  whatsappOTP?: boolean;
  sendSignWhatsapp?: boolean;
  certificateProfile?: CertificateProfile;
  signDate?: string;
  validation?: string;
  validCertificate?: boolean;
  selectedCertificateProfile?: string;
  biometricsResults?: any;
  props?: { key: string; value: string }[];
}

export interface Attachment {
  uuid?: string;
  mustSign?: boolean;
  name?: string;
  size?: number;
  type?: string;
  path?: string;
  signedPath?: string;
  thumbnail?: string;
}

export interface IdType {
  code: string;
  name: string;
  value: string;
}

export interface DocumentState {
  name: string;
  value: string;
}

export interface SignerState {
  name: string;
  value: string;
}


export interface NotificationFrequency {
  hours: number;
  times: number;
}

export enum DocumentStates {
  complete = 'COMPLETE',
  incomplete = 'INCOMPLETE',
  error = 'ERROR',
  blocked = 'BLOCKED',
  expired = 'EXPIRED',
  rejectedBySigner = 'REJECTED_BY_SIGNER',
  rejectedByApprover = 'REJECTED_BY_APPROVER',
  archived = 'ARCHIVED',
  inElaboration = 'IN_ELABORATION',
}

