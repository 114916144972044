<div class="flex flex-col max-w-240 md:min-w-160 max-h-screen -m-6">
  <!-- Header -->
  <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
    <ng-container>
      <div class="text-lg font-medium">Agregar placeholders</div>
    </ng-container>
    <button mat-icon-button type="button" (click)="closeComponent()">
      <mat-icon class="text-current" svgIcon="close"></mat-icon>
    </button>
  </div>

  <form
    *ngIf="listPlaceholders && listPlaceholders.length; else noPlaceholders"
    class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto"
    [formGroup]="placeholderForm"
    (ngSubmit)="onSubmit()"
  >
    <ng-container
      *ngFor="let placeholder of listPlaceholders"
    >
      <mat-form-field class="flex-auto w-full">
        <mat-label>{{ placeholder.name }}</mat-label>
        <input matInput [formControlName]="placeholder.name" />
        <mat-icon matPrefix svgIcon="people"></mat-icon>
      </mat-form-field>
    </ng-container>

    <!-- Actions -->
    <div
      class="flex flex-col sm:flex-row sm:items-center justify-end mt-4 sm:mt-6"
    >
      <div class="flex items-center mt-4 sm:mt-0">
        <!-- Discard -->
        <button
          type="button"
          class="ml-auto sm:ml-0"
          mat-stroked-button
          (click)="closeComponent()"
        >
          Descartar
        </button>
        <!-- Accept -->
        <ng-container>
          <button
            type="submit"
            class="ml-2 order-first sm:order-last"
            mat-flat-button
            [color]="'primary'"
            [disabled]="placeholderForm.invalid"
          >
            Aceptar
          </button>
        </ng-container>
      </div>
    </div>
  </form>
</div>

<!-- No templates templates -->
<ng-template #noPlaceholders>
  <div class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto">
    <div class="flex flex-auto flex-col items-center justify-center dark:bg-transparent">
      <mat-icon class="icon-size-24" svgIcon="dns"></mat-icon>
      <div class="mt-4 text-2xl font-semibold tracking-tight text-secondary">
        ¡El documento no cuenta con placeholders que rellenar!
      </div>
    </div>
  </div>
</ng-template>
